import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Header from "../Header";
export default class Amusement extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Header />
        <div class="ES-section about subpage smPadd">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-5 col-md-6 order-md-1 order-2">
                <div class="subH-img">
                  <img src="../images/amusement-fun.png" alt="Amusement Fun" />
                </div>
              </div>
              <div class="col-lg-7 col-md-6 order-md-2 order-1">
                <div class="middleDiv">
                  <div class="subH-cont">
                    <h1 class="text-uppercase">Amusement and Fun</h1>
                    <p class="mb-0">
                      Theme parks are nexus of storytelling, operations,
                      economics and human experience. We create the top-notch
                      theme parks, indoor playlands, laser tags, bumper cars, go
                      karts, trampoline parks, haunted houses and fun
                      attractions. We help investors in aspects of amusement and
                      entertainment planning.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ES-section p-0">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <div class="conBox W800bg">
                  <h2 class="text-uppercase">Theme Parks</h2>
                  <p class="mb-20">
                    Our theme parks let you escape from everyday life, break
                    rules and immerse yourself into a different reality. Small
                    or large, a smart masterplan that recognizes site potential
                    will pave the road toward bigger opportunity and growth.
                  </p>
                  <p>
                    A theme park will include several anchor points such as
                    kiddie rides, family rides, thrill rides, arcade games, VR
                    games, AR games, go cart, bumper cars, soft play,
                    trampoline, haunted house, wall climbing games, food stalls
                    & shops. For the theme park guest, the experience is simple:
                    immersive, entertaining and totally satisfying.
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <div class="conBox shadow R800bg">
                  <p class="mb-20">
                    Theme parks are great opportunity for strong consumer brands
                    to extend into theme park environments, as focal point for
                    commercial and resort development. Theme parks are always
                    evolving, tapping into history, culture, mythology,
                    technology and many other contemporary themes in order to
                    deepen and broaden the park’s appeal.
                  </p>
                  <p>- Laser Tag</p>
                  <p>- Trampoline</p>
                  <p>- Haunted House</p>
                  <p>- Soft play</p>
                  <p>- Go Kart</p>
                  <p>- Bumper Cars</p>
                </div>
              </div>

              <div class="col-md-12 text-center mt-50">
                <NavLink class="button" to="/projects">
                  SEE PROJECTS
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div class="ES-section about pb-0">
          <div class="container">
            <div class="row">
              <div class="col-12 text-center">
                <h1 class="text-uppercase">how we do</h1>
              </div>

              <div class="col-lg-6 col-md-12">
                <div class="conBox">
                  <h2 class="text-uppercase">
                    <span class="SN">01</span>Ride Selection and Theming
                  </h2>
                  <p>
                    A theme park’s crowd-pullers are without question, its
                    rides. We offer full coordination in selection of the rides
                    that benefit the park and integrate well with our theme and
                    other attractions. we discuss finances.
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <div class="conBox">
                  <h2 class="text-uppercase">
                    <span class="SN">02</span>research
                  </h2>
                  <p>
                    It is all about connecting the dots. Landscape architecture
                    creates memorable experience by connecting all the outdoor
                    elements, the paths, gathering, open and circulation areas,
                    plantation, special props, sound, walls, fences, bridges,
                    lighting, fountains, signages and lush green areas without
                    compromising comfort. fter getting the business vision and
                    studying the user behavior, we draw insights from the data.
                    It enables us crafting a product roadmap that solves
                    problems.
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <div class="conBox">
                  <h2 class="text-uppercase">
                    <span class="SN">03</span>Interior Design
                  </h2>
                  <p>
                    Interior design brings theme indoors, from space planning
                    through detail design to construction with technical
                    requirements and budgetary considerations. We pay attention
                    to every zone in the interior space and keep the experience
                    immersive.
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <div class="conBox">
                  <h2 class="text-uppercase">
                    <span class="SN">04</span>Master Planning
                  </h2>
                  <p>
                    From concept to execution, master plan serves as a guideline
                    of the park’s organization, circulation patterns and zones,
                    and the relationship of its individual parts. Master Plan is
                    a statement of intent and evolves into a source of reference
                    for design and construction until completion of the project
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <div class="conBox">
                  <h2 class="text-uppercase">
                    <span class="SN">05</span>Storyline & Thematic Development
                  </h2>
                  <p>
                    The layout is planned in a detail-oriented manner. We put
                    hard work in this stage and architects give undivided
                    attention to the dynamics of the space. The façade and
                    elevations of the building is designed along with the
                    interior plan. We give freedom to our architects to display
                    their creativity and come up with unique yet useful and
                    timeless designs.
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <div class="conBox">
                  <h2 class="text-uppercase">
                    <span class="SN">06</span>Architectural Design
                  </h2>
                  <p>
                    Architectural design is functional and conforms to the
                    comfort of the visitors. The structure of the buildings and
                    the spaces in between relate to the customers and assist in
                    interaction with the rides and attractions. The balance is
                    key in creating a memorable guest experience and careful
                    design for seamless park operations.
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <div class="conBox">
                  <h2 class="text-uppercase">
                    <span class="SN">07</span>Graphic Design and Way Finding
                  </h2>
                  <p>
                    We pay attention to details, signages and graphics must be
                    both useful and entertaining. Directional, informational,
                    painted, printed or constructed, signs and graphics cater
                    the very need of the users to navigate through the park.
                    Branding also plays an important role in building a
                    character of the park in visitor’s memory.
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <div class="conBox">
                  <h2 class="text-uppercase">
                    <span class="SN">08</span>Attraction Design & Management
                  </h2>
                  <p>
                    Attractions small or large offer a major opportunity to
                    expand the theme. Combination of high-tech ride systems,
                    multimedia, interactive theatre and immersive special
                    effects create breathtaking experiences. We work closely
                    with clients, construction managers, and contractors to
                    ensure the core design ideas are implemented in the time and
                    under budget. We utilize and include items that are unique
                    to theme parks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
