export const HOME = "/home";
export const Landing = "/";
export const PROJECTS = "/projects";
export const SERVICES = "/services";
export const ABOUT_US = "/about";
export const CONTACT_US = "/contact";
export const PROJECT_DETAIL = "/project/:projectTitle";
export const SERVICES_DETAIL = "/service/:serviceType";
export const ECOMMERECE_SOLUTION = "/ecommerce-solution";
export const BRANDING = "/branding";
export const BRANDING_BOOK = "/branding-book";
